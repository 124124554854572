import {useScopeTranslation} from '@uigstudio/uig-nextjs-helper';

import {ApiEndpoints} from '~constants/api';
import {FiltersWhitelistType} from '~providers/FiltersProvider';
import {ListMapComboRoute} from '~components/pages/ListMapComboRoute';
import {getStaticMapListPaths, getStaticMapListProps} from '~utils/page';

const mapFiltersWhitelist: FiltersWhitelistType = [
    'status',
    'price',
    'distanceFromHq',
    'districts',
    'partner',
];

export const listFiltersWhitelist: FiltersWhitelistType = [
    'status',
    'price',
    'distanceFromHq',
    'districts',
    'sortDirection',
    'sortBy',
];

const Index = () => {
    const t = useScopeTranslation('common');

    return (
        <ListMapComboRoute
            mapFiltersWhitelist={mapFiltersWhitelist}
            listFiltersWhitelist={listFiltersWhitelist}
            mapPageProps={{
                filteredPlotsEndpoint: ApiEndpoints.PlotsIds,
            }}
            landListProps={{
                endpoint: ApiEndpoints.PlotsList,
            }}
            layoutProps={{
                headProps: {
                    title: t('title'),
                },
            }}
        />
    );
};

export default Index;

export const getStaticProps = getStaticMapListProps;
export const getStaticPaths = getStaticMapListPaths;
